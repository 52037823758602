/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "~font-awesome/css/font-awesome.min.css";
@import "~leaflet/dist/leaflet.css";


.lit-card {
    border-radius: 25px;
    box-shadow: 0px 8px 15px 2px rgba(0, 0, 0, 0.15) !important;
    background: #fff;
}


.lit-img {
    border-radius: 25px 25px 0 0;
}

.dropdown-menu {

	background: #f2f2f2;
}